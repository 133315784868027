import React from "react";
import SharePriceDataWidget from "../../../../components/SharePriceDataWidget";
import SEO from "../../../../components/seo";

function PriceHorizontalWidget() {
  return (
    <>
      <SEO />
      <SharePriceDataWidget isHorizontal />
    </>
  );
}

export default PriceHorizontalWidget;
